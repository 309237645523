import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SpreadModalModule } from 'src/app/components/modals/spread-modal/spread-modal.module';
import { LoginModalModule } from 'src/app/components/modals/login-modal/login-modal.module';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { IconsModule } from '../elements/icons/icons.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LoginDialogComponent } from '../modals/login-dialog/login-dialog.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageAlertComponent } from '../message-alert/message-alert.component';
import { YoungMenuComponent } from 'src/app/page/young/young-menu/young-menu.component';
import { TabComponent } from 'src/app/page/young/tab/tab.component';
import { ConfirmationModalModule } from '../modals/confirmation-modal/confirmation-modal.module';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    HeaderComponent,
    SideMenuComponent,
    LoginDialogComponent,
    YoungMenuComponent,
    TabComponent
  ],
  imports: [
    SpreadModalModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    IconsModule,
    LoginModalModule,
    CommonModule,
    SlickCarouselModule,
    RouterModule,
    ConfirmationModalModule,
    ImageCropperComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    HeaderComponent,
    SideMenuComponent,
    LoginDialogComponent,
    YoungMenuComponent,
    TabComponent,
    ImageCropperComponent,
  ]
})
export class SharedModule { }
