<div
  class="alert flex flex-row justify-between items-center gap-x-3"
  [ngClass]="themeClass"
  *ngIf="show"
>
  <div class="alert-content flex gap-x-2 items-center justify-center" [ngSwitch]="themeClass">
    <i class="material-icons" *ngSwitchCase="'success'">check_circle_outline</i>
    <i class="material-icons" *ngSwitchCase="'error'">error_outline</i>
    <i class="material-icons" *ngSwitchCase="'warning'">chat_bubble_outline</i>
    <span class="message">{{ message }}</span>
  </div>
  <div class="'flex items-center">
    <a *ngIf="icon" [href]="icon === 'refresh' ? '/' : iconLink">
      <span class='material-icons'>
        {{icon}}
      </span>
    </a>
    <button class="close" (click)="hide()">
      <i class="material-icons">close</i>
    </button>
  </div>
</div>
