<footer class="mb-4">
  <div class="footer-young mt-3 flex justify-between flex-col">
    <div class="footer-content flex flex-col">
      <div class="footer-logo mb-3">
        <img src="assets/img/brand/logo.png" alt="Logo da NAU" class="footer-logo-img" width="25%">
      </div>

      <div class="links-logos">
        <ul class="links-class">
          <li class="links"><a>Central de ajuda</a></li>
          <li class="links"><a>Sobre o Projeto NAU</a></li>
          <li class="links"><a>Divulgue o NAU</a></li>
          <li class="links" href="termos-de-uso"><a>Política de Privacidade</a></li>
          <li class="links"><a>Termos de Uso</a></li>
        </ul>

        <ul class="iniciativa">
          <li><p>Uma iniciativa de</p></li>
          <li><img src="assets/img/brand/seb_logo.png" alt="" width="30%"></li>
          <li><img src="assets/img/brand/logo-tamboro.png" alt="" width="30%"></li>
        </ul>
      </div>

      <div class="copyright-social flex flex-row justify-between">
        <p class="flex">
          NAU Match &copy; Copyright 2024 <br/>
          Todos os direitos reservados.
        </p>

        <div class="footer-social flex flex-row mb-5">
          <div class="social-icons">
            <a href="https://www.facebook.com/ProjetoNau" target="_blank">
              <img src="assets/icons/Facebook.svg" alt="Facebook" class="social-icon" width="30px">
            </a>
            <a href="https://www.instagram.com/projetonau/" target="_blank">
              <img src="assets/icons/Instagram.svg" alt="Instagram" class="social-icon" width="30px">
            </a>
            <a href="https://www.linkedin.com/company/projetonau/" target="_blank">
              <img src="assets/icons/Linkedin.svg" alt="LinkedIn" class="social-icon" width="30px">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
