<header id="header" #header [ngClass]="{
  fixed: !!backgroundMaskConfig || !!isFixed,
  sticky: !backgroundMaskConfig && !isFixed,
  inverse: !!isInverse
}" [ngStyle]="
  (backgroundMaskConfig &&
  !isBackgroundColored) && {
    'background-image': 'url(' + backgroundMask + ')',
    'background-position': backgroundMaskConfig.position,
    height: backgroundMaskConfig.size
  }
">
<nav class="" [ngStyle]="(isYoung) && {'background-color': '#511B76', 'height': '160px'}">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="container relative flex h-16 items-center justify-between" [ngStyle]="(isYoung) && {'height': '160px'}">
      <a class="navbar-brand" [href]="isYoungPage || (isProfilePage && isYoung)  ? 'jovem' : ''" *ngIf="!isAuthenticated">
        <img class="logo"
          [src]="isBackgroundColored || isFixed ? '/assets/img/brand/logo-caption-white.png' : '/assets/img/brand/logo-caption-black.png'"
          alt="Nau-Match logo" height="95" />
      </a>
      <div class="collapse navbar-collapse justify-content-center mb-lg-0 mb-md-4 mb-4" [ngStyle]="(isYoung) && {'position': 'absolute'}">
        <ul class="navbar-nav">
          <li class="nav-item active mt-lg-0 mt-4" *ngFor="let link of links">
            <a class="body-xl nav-link nav-link-padding" target="_self" [href]="link.url"
              [ngStyle]="checkUrlLink(link.url)">
              {{ link.name }}
            </a>
          </li>
        </ul>
      </div>

      <div class="float-left px-xl-4 px-lg-3 px-4" *ngIf="!isAuthenticated && !!isCompany">
        <div class="row align-items-center flex">
          <button class='col-auto btn btn-primary h-100 mobile-none-button'
            (click)="scrollTo('company-register')">
            Contrate Agora
          </button>
          <div class='col-auto px-3'>
            <button (click)="redirectTo('login')" class="text-white hover:text-white border border-white hover:bg-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800" [ngClass]="{
                'btn-outline-light':
                  !isInverse || isBackgroundColored || backgroundMaskConfig,
                'btn-outline-primary': !!isInverse && !isBackgroundColored
              }">
              Login
            </button>
          </div>
          <button class="navbar-toggler col-auto" (click)="isSideMenuOpen = !isSideMenuOpen" type="button"
            data-toggle="collapse">
            <div class="menu" [ngClass]="{ 'is-collapsed': !isCollapsed }">
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
            </div>
          </button>
        </div>
      </div>
      <div class="float-left px-xl-4 px-lg-3 px-4" *ngIf="!isCompany && !isAuthenticated">
        <div class="row align-items-center">
          <button (click)="onShowLoginModal('register')"
            class='col-auto btn btn-lg btn-primary h-100 mobile-none-button'>
            Criar conta
          </button>
          <div class='col-auto px-3'>
            <button (click)="onShowLoginModal()" class="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800" [ngClass]="{
                'btn-outline-light':
                  !isInverse || isBackgroundColored || backgroundMaskConfig,
                'btn-outline-primary': !!isInverse && !isBackgroundColored
              }">
              Login
            </button>
          </div>
          <button class="navbar-toggler col-auto" (click)="isSideMenuOpen = !isSideMenuOpen" type="button"
            data-toggle="collapse">
            <div class="menu" [ngClass]="{ 'is-collapsed': !isCollapsed }">
              <div class="bar"></div>
              <div class="bar"></div>
              <div class="bar"></div>
            </div>
          </button>
        </div>
      </div>
      <div class="px-3" [ngStyle]="(isYoung) && {'width': '100%', 'padding-left': '1rem !important', 'padding-right': '1rem !important'}" *ngIf="!!isAuthenticated">
        <div dropdown placement="bottom right" container="button">
          <button id="avatar-dropdown" dropdownToggle type="button" (click)="onUpsideEvent(!this.isUpside)"
            class='body-lg btn-lg btn btn-primary dropdown-block mobile-none-button' aria-controls="avatar-dropdown">
            <div class="row w-100 mx-0 align-items-center">
              <div class="photo rounded-circle mr-3">
                <div class='avatar'>
                  <img *ngIf="!user?.photo" class="rounded-circle" src="/assets/img/perfil/users.svg" />
                  <img class="rounded-circle" width="100%" *ngIf="user.photo" [src]="user.photo" />
                </div>
              </div>
              <span class='text-line-overflow text-light' style="max-width: 200px">
                {{user?.first_name}}
              </span>
              <span class="material-icons ml-2">
                expand_more
              </span>
            </div>
          </button>


          <ul id="avatar-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
            aria-labelledby="avatar-dropdown">
            <li role="menuitem">
              <a [href]="isYoung ? 'jovem/dashboard' : 'dashboard'" class="dropdown-item">Sua conta</a>
            </li>
            <li role="menuitem" (click)="logout()">
              <a class="dropdown-item">Sair</a>
            </li>
          </ul>
        </div>

        <button type="button" class="mt-3 relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false" (click)="isSideMenuOpen = !isSideMenuOpen">
          <div class="menu" [ngClass]="{ 'is-collapsed': !isCollapsed }">
            <img src="assets/icons/menu-hamburger.svg" alt="">
          </div>
        </button>

        <ul class="float-right flex list-inline first-list-header-young">
          <li class="list-inline-item">
            <a class="navbar-brand a-logo-match" [href]="isYoungPage || (isProfilePage && isYoung)  ? 'jovem' : ''">
              <img class="logo"
                [src]="isBackgroundColored || isFixed ? '/assets/img/brand/logo-caption-black.png' : '/assets/img/brand/logo-caption-white.png'"
                alt="Nau-Match logo"
                width="95" />
            </a>
          </li>
          <li class="px-3 list-inline-item">
            <a class="navbar-brand" [href]="'#'">
              <img class="logo"
                [src]="'/assets/img/brand/match-by-tamboro.svg'"
                alt="Match by Tamboro logo"
                width="90" />
            </a>
          </li>
          <!-- <li class="list-inline-item">
            <a class="navbar-brand" [href]="'#'">
              <img class="logo"
                [src]="'/assets/img/push-notification.svg'"
                alt="Push Notification icon"
                width="26" />
            </a>
          </li> -->
        </ul>

        <ul class="flex list-inline second-list-header-young">
          <li class="list-inline-item">
            <div class="row w-100 mx-0 align-items-center">
              <div class="photo rounded-circle mr-3">
                <div class="avatar" (click)="routerProfile()">
                  <img *ngIf="!user?.photo" class="rounded-circle" src="/assets/img/perfil/profile-photo-young.svg" />
                  <img class="rounded-circle" width="100%" *ngIf="user.photo" [src]="user.photo" />
                </div>
              </div>
            </div>
          </li>
          <li class="list-inline-item">
            <div class="row w-100 mx-0 align-items-center">
              <span class='text-line-overflow text-light' style="max-width: 200px" (click)="routerProfile()">
                <strong>@{{user?.first_name}}</strong>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
</header>

<app-side-menu (onClose)="isSideMenuOpen = !isSideMenuOpen" [links]="links" [isYoungPage]="isYoungPage" [user]="user"
[isOpen]="isSideMenuOpen">
</app-side-menu>
<app-login-modal [initialStep]="initialStep" [isOpen]="isLoginOpen" (onClose)="closeModal()"></app-login-modal>
