<div class="snackbar p-3" *ngIf="isOpen">
    <ng-content></ng-content>
    <div class='grid grid-cols-12 w-full justify-center pb-4 pt-3'>
        <div class='col-span-8 pl-0 pr-3'>
            <button role="button" class='btn w-100 confirm-button' (click)="onConfirmHandle()">{{confirmLabel}}</button>
        </div>
        <div class='col-span-4 pr-0 pl-0'>
            <button role="button" class='btn w-100 cancel-button flex flex-row items-center justify-center' (click)="onCancelHandle()">{{cancelLabel}}
              <i class="material-icons">east</i>
            </button>
        </div>
    </div>
</div>
