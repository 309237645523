import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UserService } from 'src/app/services/user/user.service';
import { Step } from '../login-modal.component';
import { SHA1 } from 'crypto-js';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { MailService } from 'src/app/services/mail/mail.service';
import { SnackbarService } from 'ngx-snackbar';
import { emailConfirmationConfig } from 'src/app/mockup/loginConstants';
@Component({
  selector: 'modal-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input() whichStep?: Step | null
  @Input() token?: string
  @Input() email?: string
  @Output() changeStepEvent = new EventEmitter
  isPasswordVisible: boolean = false
  form: UntypedFormGroup

  passwordError?: boolean
  emailError?: boolean
  confirmEmailError?: boolean

  @Output() onExtraStepFieldsChange = new EventEmitter()

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private mailService: MailService,
    private loadingService: LoadingService,
    private snackbarService: SnackbarService,
    private authService: AuthenticationService
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })
   }

  ngOnInit(): void {
  }

  registerResult(result: { success: boolean, msg: string }) {
    if (result.success) {
      this.onExtraStepFieldsChange.emit(emailConfirmationConfig)
      this.onChangeStep('extra-step')
    }
  }

  onSendNewConfirmEmail(){
    const emailConfig = {
      templateId: 'd-e3a957dfdede45a7b3633ded0167e428',
      url:  `/perfil?tkn=`,
      urlParams: '&confirmEmail=true',
      email: this.f.email.value
    }
    this.mailService.sendDynamicEmail(emailConfig).subscribe(data => {
      this.loadingService.show = false
      this.confirmEmailError = false
      this.snackShow()
    }, error => {
      this.confirmEmailError = false
      this.loadingService.show = false
    })
  }

  onChangeStep(step: Step): void {
    this.changeStepEvent.emit(step)
  }

  ngOnChanges(simpleChanges: any): void {
    if (simpleChanges.whichStep.currentValue) {
      this.onControlsSubscribe()
    }
  }

  onControlsSubscribe() {
    this.form.get('password')?.valueChanges.subscribe(val => {
      if (this.whichStep === 'login' || this.whichStep === 'register') {
        this.form.controls['password'].setValidators([Validators.required]);
      } else {
        this.form.controls['password'].clearValidators();
      }
      this.form.controls['password'].updateValueAndValidity();
    });

    this.form.get('confirmPassword')?.valueChanges.subscribe(val => {
      if (this.whichStep === 'register') {
        this.form.controls['confirmPassword'].setValidators([Validators.required]);
      } else {
        this.form.controls['confirmPassword'].clearValidators();
      }
      this.form.controls['confirmPassword'].updateValueAndValidity();
    });
  }

  onSubmitHandle() {
    let user;
    const password = SHA1(this.form.value.password).toString();
    if (this.whichStep === 'register') {
      user = this.form.value
      this.onSubmit(user)
    }

    else if (this.whichStep === 'login') {
      user = {
        email: this.form.value.email,
        password
      }
      this.onSubmit(user)
    }
    else if (this.whichStep === 'forgot') {
      const config = {
        templateId: "d-3795eb14a0dc4c778c86b88744890a06",
        email: this.form.value.email
      }
      this.onSendEmail(config)
    }
    else {
      user = {
        email: this.form.value.email
      }
      this.onSubmit(user)
    }
  }

  onSendEmail(config) {
    this.loadingService.show = true
    this.mailService.sendDynamicEmail(config).subscribe(data => {
      this.loadingService.show = false
      this.onChangeStep('extra-step')
    }, error => {
      this.loadingService.show = false
    })
  }

  snackShow() {
    this.snackbarService.add({
      msg: `<strong>Email enviado!</strong>`,
      timeout: 5000,
      action: {
        text: '',
        onClick: () => {

        }
      }
    });
  }

  onSubmit(user: any) {
    this.passwordError = undefined
    this.emailError = undefined
    this.confirmEmailError = undefined
    this.loadingService.show = true
    if (this.whichStep === 'register') {
    }
    else if (this.whichStep === 'login') {

    }
  }

  get f() {
    return this.form.controls;
  }

}
