<div id="sidenav" class="sidenav" [style.box-shadow]="isOpen ?
    'rgb(0 0 0 / 70%) 0px 8px 10px -5px, rgb(0 0 0 / 35%) 0px 16px 24px 2px, rgb(0 0 0 / 20%) 0px 6px 30px 5px' : ''
">

    <div class="header">
      <button class="button-back" (click)="closeNav()">
        <i class="material-icons">west</i>
      </button>

      <div class="box-user-authenticated row w-100 mx-0 align-items-center" *ngIf="isAuthenticated">
        <div class="photo mr-3">
          <div class="avatar" (click)="routerProfile()">
            <img *ngIf="!user?.photo" class="" src="/assets/img/perfil/profile-photo-young.svg" />
            <img [ngClass]="{ 'isPhoto': user?.photo }" width="100%" *ngIf="user?.photo" [src]="user?.photo" />
          </div>
        </div>
      </div>
    </div>

    <div class="box-user-non-logged row w-100 mx-0 align-items-center" *ngIf="!isAuthenticated">
      <div class="non-logged-image">
        <img src="assets/img/menu/non-logged/Logo-NAU.svg" alt="">
      </div>
      <div class="non-logged-btns">
        <div class="snackbar p-3" *ngIf="isOpen">
          <ng-content></ng-content>
          <div class='grid grid-cols-12 w-full justify-center pb-4 pt-3'>
            <div class='col-span-6 pl-0'>
              <button role="button" class='btn w-100 login flex flex-row items-center justify-center' (click)="openModal()">
                ENTRAR
                <i class="material-icons">east</i>
              </button>
            </div>
            <div class='col-span-6 w-full pl-0'>
              <button role="button" class='btn w-100 register flex flex-row items-center justify-center' (click)="register()">CADASTRE-SE GRÁTIS</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container>
        <!--a href="javascript:void(0)" class="ml-4 closebtn" (click)="closeNav()">&times;</!--a-->
        <span *ngIf="hasHomeLinks && isAuthenticated" class="menu-list">
            <a *ngFor="let link of links" class='body-xl pl-4 py-2 d-block' target="_self" href="{{link.url}}">
              <img class="rounded-circle-item" width="100%" *ngIf="link.image" [src]="link.image" />
              <label for="">{{link.name}}</label>
            </a>
            <a class='body-xl pl-4 py-2 d-block' (click)="logout()">
              <img class="rounded-circle-item" width="100%" src="assets/img/menu/sair.svg" />
              <label for="">Sair</label>
            </a>
        </span>
    </ng-container>

    <hr class='divider' />

    <div class="sidebar-menu-footer">
      <ul>
        <!-- <li>Central de ajuda</li>
        <li>Sobre o Projeto NAU</li>
        <li>Divulgue o NAU</li> -->
        <!-- <li>Política de Privacidade</li> -->
        <li>
          <a href="termos-de-uso">
            Termos de Uso
          </a>
        </li>
      </ul>
    <div>
</div>
