import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'young-menu',
  templateUrl: './young-menu.component.html',
  styleUrls: ['./young-menu.component.scss']
})
export class YoungMenuComponent implements OnInit {
  activeSection: string = "perfil";
  isWarningModalOpen: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.checkActiveRoute();

    // This is to check the active route and change the activeSection variable if the route changes
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkActiveRoute();
    });
  }

  openWarningModal(): void {
    this.isWarningModalOpen = true;
  }

  closeWarningModal(): void {
    this.isWarningModalOpen = false;
  }

  checkActiveRoute() {
    const currentRoute = this.router.url;

    switch (true) {
      case currentRoute.includes('dashboard'):
        this.activeSection = 'dashboard';
        break;
      case currentRoute.includes('vagas'):
        this.activeSection = 'vagas';
        break;
      case currentRoute.includes('candidaturas'):
        this.activeSection = 'candidaturas';
        break;
      case currentRoute.includes('cursos'):
        this.activeSection = 'cursos';
        break;
      default:
        this.activeSection = 'dashboard';
        break;
    }
  }

  changeSection(section: string) {
    this.activeSection = section;

    this.router.navigate([`/jovem/${section}`]);
  }
}
