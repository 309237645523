<div class="container">
  <div class="confirm-phase" *ngIf="!isEmailNotReceived && !confirmEmailSent">
    <div class="content flex flex-col gap-2 items-center justify-center">
      <img src="../../../../assets/img/design/icone_valida_email 1.svg" alt="">
      <h1 class="title-confirmation">Acesse seu e-mail e confirme sua conta.</h1>
      <p class="text-checkmail">Abra o e-mail de confirmação que enviamos para <b>{{this.email}}</b> e clique em <b>”CONFIRMAR CONTA”.</b></p>
    </div>

    <div class="buttons flex flex-col gap-2 items-center">
      <a class="primary-button" href="/jovem">Vou confirmar minha conta já!</a>
      <a class="not-received" (click)="emailNotReceived()">Não recebi o e-mail</a>
    </div>
  </div>

  <div class="confirm-phase" *ngIf="isEmailNotReceived && !confirmEmailSent">
    <div class="content">
      <h1 class="title-confirmation center">Não recebeu o e-mail de confirmação?</h1>
      <p class="text-checkmail w-70">Verifique sua caixa de SPAM ou aguarde alguns minutos. Caso não tenha recebido, clique no botão abaixo para receber um novo e-mail:</p>
      <!-- <div class='text-center' style="height: 60px; margin: 3rem 10px 2rem;">CAPTCHA</div> -->
    </div>

    <div class="buttons">
      <button class="primary-button" (click)="sendMail()">Receber um novo-email</button>
    </div>
  </div>

  <div class="confirm-phase" *ngIf="confirmEmailSent">
    <div class="content flex flex-col gap-2 items-center justify-center">
      <img src="../../../../assets/img/design/icone_valida_email 1.svg" alt="">
      <h1 class="title-confirmation">Um novo e-mail de confirmação foi enviado!</h1>
      <p class="text-checkmail">Abra o e-mail de confirmação que enviamos para <b>{{this.email}}</b> e clique em <b>”CONFIRMAR CONTA”.</b></p>
    </div>
    <div class="buttons flex flex-col gap-2 items-center">
      <a class="primary-button" href="/jovem">Vou confirmar minha conta já!</a>
      <a class="not-received" (click)="emailNotReceived()">Não recebi o e-mail</a>
    </div>
  </div>
</div>
