import { Component, Input, OnInit } from '@angular/core';
import { MailService } from 'src/app/services/mail/mail.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() email;
  confirmEmailSent: boolean = false;
  isEmailNotReceived: boolean;

  constructor(
    private modalService: ModalService,
    private mailService: MailService
    ) { }

  ngOnInit(): void {

  }

  closeModal() {
    this.modalService.isAuthModalOpen = false
  }

  emailNotReceived() {
    this.confirmEmailSent = false;
    this.isEmailNotReceived = true;

  }

  sendMail() {
    const config = {
      templateId: 'd-e3a957dfdede45a7b3633ded0167e428',
      email: this.email,
      url:  `/jovem/walkthrough?tkn=`,
      urlParams: '&confirmEmail=true'
    }
    this.onSendEmail(config)
  }

  onSendEmail(config) {
    this.mailService.sendDynamicTemplateEmail(config).subscribe(data => {
      this.confirmEmailSent = true;
    }, error => {
    })
  }

}
