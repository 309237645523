<div class="container" [style.display]="confirmEmailError ? 'none' : 'block'">
  <button class="close" (click)="closeModal()">
    <i class="material-icons">close</i>
  </button>

  <h1>Acessar minha conta</h1>

  <form [formGroup]="form" (submit)="onLogin()" class="login w-100 flex items-center justify-center flex-col">
    <input type="text" formControlName="login" placeholder="E-mail ou celular com DDD">

    <div class="password-field">
      <input type="password" formControlName="password" placeholder="Senha" [type]="show ? 'text' : 'password'">
      <i class="material-icons" (click)="showPassword()">
        {{show ? 'visibility_off' : 'visibility'}}
      </i>
    </div>

    <span class="forgot"><a href="forgot">Esqueci minha senha</a></span>

    <button type="submit" class="submit">Entrar</button>
  </form>

  <hr>

  <div class="cadastro flex items-center justify-center flex-col">
    <span class="account">Ainda não possui uma conta?</span>
    <a href="/register" class="register">Cadastre-se grátis</a>
  </div>
</div>

<confirmation-modal [email]="f.login.value" *ngIf="confirmEmailError"></confirmation-modal>
