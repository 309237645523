
export const links = [
    {
      url: "jovem/empresas",
      name: "Empresa"
    },
    {
      url: "jovem/vagas",
      name: "Vagas"
    },
    {
      url: "jovem/cursos",
      name: "Cursos"
    },
    {
      url: "",
      name: "Área da empresa"
    }
]

export const authenticatedLinks = [
    {
      image: "assets/img/menu/perfil.svg",
      url: "jovem/perfil",
      name: "Perfil"
    },
    {
      image: "assets/img/menu/vaga.svg",
      url: "jovem/vagas",
      name: "Vagas"
    },
    {
      image: "assets/img/menu/candidatura.svg",
      url: "jovem/candidaturas",
      name: "Candidaturas"
    },
    {
      image: "assets/img/menu/cursos.svg",
      url: "jovem/cursos",
      name: "Cursos"
    },
    {
      image: "assets/img/menu/configuracoes.svg",
      url: "jovem/configuracoes",
      name: "Configurações da conta"
    },
    /*{
      image: "assets/img/menu/sair.svg",
      url: "",
      name: "Sair"
    },
    {
      url: "jovem/empresas",
      name: "Empresa"
    },*/
]
