export const environment = {
  production: false,
  nau_url: 'https://alpha-new-match.tamboro.com.br/',
  web_url_nau: 'https://alpha-naumatch.tamboro.com.br/',
  mobile_url_nau: 'https://alpha-naumatch.tamboro.com.br/mobile/',
  url_api: 'https://alpha-new-match-api.tamboro.com.br/',
  auth_url_api: 'https://alpha-new-match-api.tamboro.com.br/auth/',

  googleUrl: `https://alpha-nau.tamboro.com.br/nau-match-api/google/login`
};
