import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/helper/auth.guard';
import { FooterOnlyComponent } from './components/layouts/footer-only/footer-only.component';

const routes: Routes = [
  {
    path: '',
    component: FooterOnlyComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('src/app/page/young/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'jovem',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('src/app/page/young/home/home.module').then((m) => m.HomeModule),
          },
          {
            path: 'empresas',
            loadChildren: () =>
              import('src/app/page/young/companies/companies.module').then(
                (m) => m.CompaniesModule
              ),
          },
          // {
          //   path: 'vagas',
          //   loadChildren: () =>
          //     import('src/app/page/young/vacancies/vacancies.module').then(
          //       (m) => m.VacanciesModule
          //     ),
          // },
          {
            path: 'walkthrough',
            canActivate: [AuthGuard],
            data: { roles: 'Young' },
            loadChildren: () =>
              import('src/app/page/young/walkthrough/walkthrough.module').then(
                (m) => m.WalkthroughModule
              ),
          },
          {
            path: 'courses',
            loadChildren: () =>
              import('src/app/page/young/skills/skills.module').then(
                (m) => m.SkillsModule
              ),
          },
          {
            path: 'cursos',
            loadChildren: () =>
              import('src/app/page/young/courses/courses.module').then(
                (m) => m.CoursesModule
              ),
          },
        ],
      },
      {
        path: 'termos-de-uso',
        loadChildren: () =>
          import('src/app/page/termos/termos.module').then((m) => m.TermosModule),
      },
    ]
  },

  {
    path: '',
    children: [
      {
        path: 'reports',
        loadChildren: () =>
          import('src/app/page/match-report/match-report.module').then((m) => m.MatchReportModule),
      },
      {
        path: 'course-goals/:id_formative_itinerary',
        loadChildren: () =>
          import('src/app/page/courses-goals/courses-goals.module').then((m) => m.CoursesGoalsModule),
      },
      {
        path: 'jovem',
        children: [
          {
            path: 'dashboard',
            data: { roles: 'Young' },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import('src/app/page/dashboard/dashboard.module').then((m) => m.DashboardModule)
          },
          {
            path: 'candidaturas',
            data: { roles: 'Young' },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import('src/app/page/young/young-candidacies/young-candidacies.module').then(
                (m) => m.YoungCandidaciesModule
              )
          },
          {
            path: 'vagas',
            data: { roles: 'Young' },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import('src/app/page/young/young-vacancies/young-vacancies.module').then(
                (m) => m.YoungVacanciesModule
              )
          },
          {
            path: 'perfil',
            canActivate: [AuthGuard],
            data: { roles: ['Owner', 'Young', 'Employee-1', 'Employee-2', 'Employee-3'] },
            loadChildren: () =>
              import('src/app/page/perfil/perfil.module').then((m) => m.PerfilModule),
          },
          {
            path: 'profile',
            canActivate: [AuthGuard],
            data: { roles: ['Owner', 'Young', 'Employee-1', 'Employee-2', 'Employee-3'] },
            loadChildren: () =>
              import('src/app/page/young/profile/profile.module').then((m) => m.ProfileModule),
          },
          {
            path: 'vaga/:id_match_vacancy',
            data: { roles: 'Young' },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import('src/app/page/young/vacancy-page/vacancy-page.module').then(
                (m) => m.VacancyPageModule
              ),
          },
          {
            path: 'configuracoes',
            data: { roles: 'Young' },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import('src/app/page/young/config-account/config-account.module').then((m) => m.ConfigAccountModule)
          },
        ]
      },
      {
        path: '',
        loadChildren: () =>
          import('src/app/page/young/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'termos-de-uso',
        loadChildren: () =>
          import('src/app/page/termos/termos.module').then((m) => m.TermosModule),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('src/app/page/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'forgot',
        loadChildren: () =>
              import('src/app/page/forgot/forgot.module').then((m) => m.ForgotModule)
      },
      {
        path: 'register',
        loadChildren: () =>
          import('src/app/page/register/register.module').then((m) => m.RegisterModule),
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        data: { roles: ['Owner', 'Employee-1', 'Employee-2', 'Employee-3', 'Admin', 'Superadmin'] },
        loadChildren: () =>
          import('src/app/page/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'perfil',
        canActivate: [AuthGuard],
        data: { roles: ['Owner', 'Young', 'Employee-1', 'Employee-2', 'Employee-3'] },
        loadChildren: () =>
          import('src/app/page/perfil/perfil.module').then((m) => m.PerfilModule),
      },
      {
        path: 'alertastestecorporativo',
        loadChildren: () =>
          import(
            'src/app/page/alertastestecorporativo/alertastestecorporativo.module'
          ).then((m) => m.AlertastestecorporativoModule),
      },
      {
        path: 'assinatura',
        loadChildren: () =>
          import('src/app/page/assinatura/assinatura.module').then(
            (m) => m.AssinaturaModule
          ),
      },
      {
        path: 'assinaturagestao',
        loadChildren: () =>
          import('src/app/page/assinaturagestao/assinaturagestao.module').then(
            (m) => m.AssinaturagestaoModule
          ),
      },
      {
        path: 'assinaturahistorico',
        loadChildren: () =>
          import('src/app/page/assinaturahistorico/assinaturahistorico.module').then(
            (m) => m.AssinaturahistoricoModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: 'jovem', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollOffset: [0, 0],
    anchorScrolling: "enabled",
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


