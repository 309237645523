import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageType } from './message-alert.component';

@Injectable({
  providedIn: 'root'
})
export class MessageAlertService {

  private alertSubject = new Subject<any>();
  alertState = this.alertSubject.asObservable();

  constructor() { }

  show(message: string, type?: MessageType, icon?: string, iconLink?: string) {
    this.alertSubject.next({ message, type, show: true, icon, iconLink });
  }
}
