import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {

  @Output() tabChanged = new EventEmitter<string>();

  activeTab: string = 'candidaturas';

  constructor() { }

  ngOnInit(): void {
  }

  changeTab(tab: string) {
    this.activeTab = tab;
    this.tabChanged.emit(tab);
  }

}
