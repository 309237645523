<div class="menu">
  <ul class="list-inline">
    <li (click)="changeSection('dashboard')" [class.active]="activeSection === 'dashboard'" class="list-inline-item active">
      <img src="/assets/img/dash-young/dash-menu/perfil.svg" alt="Ícone de perfil do menu da home do jovem." class="list-icon">
      <p>Perfil</p>
    </li>
    <li (click)="changeSection('vagas')" [class.active]="activeSection === 'vagas'" class="list-inline-item">
      <img src="/assets/img/dash-young/dash-menu/vaga.svg" alt="Ícone de vaga do menu da home do jovem." class="list-icon">
      <p>Vagas</p>
    </li>
    <li (click)="changeSection('candidaturas')" [class.active]="activeSection === 'candidaturas'" class="list-inline-item">
      <img src="/assets/img/dash-young/dash-menu/candidatura.svg" alt="Ícone de candidatura do menu da home do jovem." class="list-icon">
      <p>Candidaturas</p>
    </li>
    <li (click)="changeSection('cursos')" [class.active]="activeSection === 'cursos'" class="list-inline-item">

      <img src="/assets/img/dash-young/dash-menu/cursos.svg" alt="Ícone de cursos do menu da home do jovem." class="list-icon">
      <p>Cursos</p>
    </li>
  </ul>
</div>

<div class="modal-container" [ngClass]="{'modal-open': isWarningModalOpen}">
  <img src="../../../../assets/icons/error.svg" alt="Erro">
  <h1 class="maintenance">Ops! Essa área está em manutenção.</h1>
  <p>Assim que os cursos estiverem disponíveis por aqui, a gente te avisa :)</p>
  <button (click)="closeWarningModal()">OK</button>
</div>
<div class="page-overlay" [class.active]="isWarningModalOpen"></div>
