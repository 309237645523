import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SHA1 } from 'crypto-js';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { MessageType } from '../../message-alert/message-alert.component';
import { MessageAlertService } from '../../message-alert/message-alert.service';

@Component({
  selector: 'login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  form: FormGroup;
  confirmEmailError: boolean
  emailError: boolean
  passwordError: boolean
  show: boolean = false

  constructor(
    public modalService: ModalService,
    public authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private messageAlertService: MessageAlertService
  ) {
    this.form = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', Validators.required],
    })
  }

  get f() {
    return this.form.controls;
  }

  get messageType() {
    return MessageType;
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalService.isAuthModalOpen = false
  }

  onLogin() {
    const password = SHA1(this.form.value.password).toString();
    const user = {
      email: this.form.value.login,
      password
    }
    this.authService.login(user, true).subscribe(data => {
      // this.loadingService.show = false
      this.modalService.isAuthModalOpen = false
    }, err => {
      if(err.error.code === 2) {
        this.passwordError = true
        this.messageAlertService.show('Senha incorreta', this.messageType.Error);
      }
      else if(err.error.code === 3) {
        this.emailError = true
        this.messageAlertService.show('E-mail não encontrado', this.messageType.Error);
      }
      else if (err.error.error == 'confirmEmail'){
        this.confirmEmailError = true
        this.messageAlertService.show('Confirme seu e-mail antes de fazer login', this.messageType.Warning);
      } else {
        this.messageAlertService.show('Ops! Parece que sua internet ficou um pouco lenta. Por favor recarregue a página.', this.messageType.Error, 'refresh');
      }
    })
  }

  showPassword() {
    this.show = !this.show
  }
}
