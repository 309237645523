import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageAlertService } from './message-alert.service';

export enum MessageType {
  Error,
  Success,
  Warning
}

@Component({
  selector: 'message-alert',
  templateUrl: './message-alert.component.html',
  styleUrls: ['./message-alert.component.scss']
})

export class MessageAlertComponent implements OnInit {

  show: boolean;
  message: string;
  type: MessageType;
  icon: string
  iconLink: string
  private alertSubscription: Subscription;

  constructor(private messageAlertService: MessageAlertService) { }

  ngOnInit(): void {
    this.alertSubscription = this.messageAlertService.alertState.subscribe((state) => {
      if (state.type !== undefined) {
        this.type = state.type;
      } else {
        this.type = MessageType.Success;
      }

      this.icon = state.icon
      this.iconLink = state.iconLink
      this.message = state.message;
      this.show = state.show;

      setTimeout(() => {
        this.show = false;
      }, 5000);
    })
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
  }

  hide() {
    this.show = false;
  }

  get themeClass() {
    switch(this.type) {
      case MessageType.Error:
        return 'error';
      case MessageType.Success:
        return 'success';
      case MessageType.Warning:
        return 'warning';
      default:
        return 'error';
    }
  }
}
